<template>
  <CustomTable
    :headers="headers"
    :items="tableData"
    hide-default-footer
    loading-text="Đang tải dữ liệu ..."
    classPaging="pt-2"
  >
    <template v-slot:[`item.action`]="{ item }">
      <a href="#" @click="goDetail(item.id)">Chi tiết</a>
    </template>
    <template v-slot:[`body.append`]>
      <tr v-if="tableData.length > 0">
        <td colspan="3" class="text-center">Tổng</td>
        <td class="text-center">{{ formatnumber(totalQuantity) }}</td>
        <td class="text-center">{{ formatnumber(totalPrice) }}</td>
        <td></td>
      </tr>
    </template>
  </CustomTable>
</template>
<script>
import { numberFormat } from "@/utils/moneyFormat";
export default {
  props: {
    data: { type: Array, default: () => [] },
  },
  data: () => ({
    headers: [
      {
        text: "STT",
        width: "20",
        value: "stt",
        sortable: false,
        align: "center",
      },
      {
        text: "Số tờ khai xuất",
        value: "declare_out_no",
        sortable: false,
        align: "center",
      },
      {
        text: "Số tờ khai nhập",
        value: "declare_in_no",
        sortable: false,
        align: "center",
      },
      { text: "Tổng tiền", value: "price", sortable: false, align: "center" },
      {
        text: "Khối lượng",
        value: "quantity",
        sortable: false,
        align: "center",
      },
      { text: "Thao tác", value: "action", sortable: false, align: "center" },
    ],
    totalQuantity: 0,
    totalPrice: 0,
  }),
  computed: {
    tableData() {
      let result = this.data ? this.data : [];
      result.map((x, i) => {
        this.totalQuantity += parseFloat(x.quantity);
        this.totalPrice += parseFloat(x.price);
        return {
          stt: i + 1,
          ...x,
          price: numberFormat(x.price),
          quantity: numberFormat(x.quantity),
        };
      });
      return result;
    },
  },
  methods: {
    goDetail(id) {
      const { href } = this.$router.resolve({
        path: `/tokhai/chinhsuatokhaihaiquan/${id}`,
      });
      window.open(href, "_blank");
    },
    formatnumber(number) {
      return numberFormat(number);
    },
  },
};
</script>