<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT HÓA ĐƠN" : "THÊM HÓA ĐƠN"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Tên công ty</div>
              <v-autocomplete
                prepend-inner-icon="mdi-image-area"
                v-model="form.company_out_id"
                :items="customersList"
                outlined
                dense
                item-text="name"
                item-value="id"
                :rules="company_out_idRules"
                @change="onChangeCompanyOut"
                filled
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Tên công ty mua</div>
              <v-autocomplete
                prepend-inner-icon="mdi-image-area"
                v-model="form.company_in_id"
                :items="companysList"
                outlined
                dense
                item-text="name"
                item-value="id"
                :rules="company_in_idRules"
                @change="onChangeCompanyIn"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Số Hóa đơn</div>
              <v-text-field
                v-model="form.bill_no"
                placeholder="Số hoá đơn"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="bill_noRules"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Nhập ngày hóa đơn</div>
              <v-menu
                v-model="showPickBillDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.bill_date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :rules="bill_dateRules"
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.bill_date"
                  @input="showPickBillDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Số tiền</div>
              <v-text-field
                v-model="form.price"
                placeholder="Số tiền"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="priceRules"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">File chứng từ</div>
              <UploadFile
                :typeAllow="['xlsx', 'xls', 'pdf']"
                :sizeAllow="20"
                v-model="form.documents"
                multiple
                :editing="edit"
              />
            </v-col>
          </v-row>
          <v-row v-if="edit">
            <v-col cols="12">
              <TableCustomsDeclares :data="form.customs_declares" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeForm" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import API from "@/api/hoadon";
import { getCompanyManagers } from "@/api/congty";
import { TYPE } from "@/constants/congtynhapkhau";
import TableCustomsDeclares from "./table";
import { TYPE as TYPE_XUAT } from "@/constants/congtyxuatkhau";
export default {
  components: {
    TableCustomsDeclares,
  },
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    fileList: [],
    customersList: [],
    companysList: [],
    showPickBillDate: false,
    form: {
      company_out_id: null,
      company_out_name: null,
      company_in_id: null,
      company_in_name: null,
      bill_no: null,
      bill_date: null,
      price: null,
      contract_name: null,
      contract_path: null,
      customs_declares: null,
      status: null,
      file: [],
    },
    company_out_idRules: [(v) => !!v || "Tên công ty không thể bỏ trống"],
    company_in_idRules: [(v) => !!v || "Tên công ty mua không thể bỏ trống"],
    bill_noRules: [(v) => !!v || "Số Hóa đơn không thể bỏ trống"],
    bill_dateRules: [(v) => !!v || "Ngayf Hóa đơn không thể bỏ trống"],
    priceRules: [(v) => !!v || "Số tiền không thể bỏ trống"],
  }),
  computed: {},
  created() {
    this.getAllCustomers();
    this.getAllCompany();
  },
  methods: {
    closeForm() {
      if (this.$refs["files"] !== undefined) {
        this.$refs["files"].value = null;
      }
      this.show = false;
      this.fileList = [];
    },
    async getAllCustomers() {
      this.customersList = await getCompanyManagers({
        type: TYPE_XUAT,
        isPaging: false,
      });
    },
    async getAllCompany() {
      this.companysList = await getCompanyManagers({
        type: TYPE,
        isPaging: false,
      });
    },
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        company_out_id: null,
        company_out_name: null,
        company_in_id: null,
        company_in_name: null,
        bill_no: null,
        bill_date: null,
        price: null,
        contract_name: null,
        contract_path: null,
        customs_declares: null,
        status: null,
        file: [],
      };
      this.fileList = [];
      if (this.$refs["files"] !== undefined) {
        this.$refs["files"].value = null;
      }
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        let data = { ...this.form };
        if (data.documents && data.documents.some((x) => x.file)) {
          data.files = data.documents.map((x) => x.file);
          delete data.documents;
        }
        const res = await API.create(data);
        this.show = false;
        this.btnLoading = false;
        if (res && res.isDone) {
          this.$emit("on-done");
          this.clearData();
          this.$refs["files"].value = null;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        let data = { ...this.form };
        if (data.documents && data.documents.some((x) => x.file)) {
          data.docs = data.documents.filter((x) => x.id);
          data.files = data.documents.filter((x) => !x.id).map((x) => x.file);
        }
        delete data.documents;
        delete data.customer;
        delete data.nguoi_tiep_nhan;
        const res = await API.update(this.form.id, data);
        this.show = false;
        this.btnLoading = false;
        if (res && res.isDone) {
          this.$emit("on-done");
          this.clearData();
        }
      }
    },
    clearData() {
      this.fileList = [];
      this.form = {
        company_out_id: null,
        company_out_name: null,
        company_in_id: null,
        company_in_name: null,
        bill_no: null,
        bill_date: null,
        price: null,
        contract_name: null,
        contract_path: null,
        customs_declares: null,
        status: null,
        file: [],
      };
    },
    onChangeCompanyOut(e) {
      this.form.company_out_name = this.customersList.find(
        (item) => item.id === e
      ).name;
    },
    onChangeCompanyIn(e) {
      this.form.company_in_name = this.companysList.find(
        (item) => item.id === e
      ).name;
    },
  },
};
</script>
